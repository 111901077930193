import React, { useState, useEffect } from 'react';
import {
	Col
} from 'reactstrap';
import Speech from "speak-tts";
import SpeechRecognition from "react-speech-recognition";
import MicRecorder from "mic-recorder-to-mp3";
import getBlobDuration from "get-blob-duration";
import AudioBox from "components/NewPractice/Speaking/ReadAloud/AudioBox";
import SpeechToText from 'components/NewPractice/Speaking/SpeechRecognition/SpeechRecognition';
import StayConnected from 'components/NewPractice/Speaking/StayConnected';

let Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default function ReadAloud({
	questionData,
	resultMode,
	sendToPracticeFile,
	questionStrategy,
	updateQuestionStrategy
}) {

	const NORMAL = 1;
	const ONELINE = 2;
	const PRE_LISTENING = 0;
	const LISTENING = 1;
	const LISTENING_FINISH = 2;

	const [strategy, setStrategy] = useState(questionStrategy);
	const [listening, setListening] = useState(PRE_LISTENING);
	const [recordedAudioURL, setRecordedAudioURL] = useState("");
	const [transcript, setTranscript] = useState("");
	const [blobFile, setBlob] = useState(null);
	const [fileBuffer, setBuffer] = useState(null);

	const updateTranscript = (trans) => {
		setTranscript(trans);
	}

	const normalStrategy = () => {
		setStrategy(NORMAL);
		updateQuestionStrategy(NORMAL);
	}

	const oneLineStrategy = () => {
		setStrategy(ONELINE);
		updateQuestionStrategy(ONELINE);
	}

	const selectSpan = (text) => {
		const speech = new Speech();
		speech.speak({
			text
		});
	}

	const updateListeningState = (value) => {
		setListening(value);
	}


	const filtered_question = questionData[0].question
		.replace(/<span[^>]*>?/g, " __")
		.replace(/<[^>]*span>?/g, "__");


	const startListening = () => {
		SpeechRecognition.startListening({ continuous: true, language: "en-IN" });

		Mp3Recorder = new MicRecorder({ bitRate: 128 });
		Mp3Recorder.start();
	}

	const stopListening = () => {
		SpeechRecognition.stopListening();

		Mp3Recorder.stop()
		.getMp3()
		.then(async ([buffer, blob]) => {
			setBlob(blob);
			setBuffer(buffer);
		})
		.catch(err => {
			console.log('Error Stopping.', err);
		});
	}

	const getBlobURL = (blob, file) => {
		let url = null;
		try {
			url = URL.createObjectURL(file);
		} catch (err) {
			url = URL.createObjectURL(blob);
		}
		return url;
	};

	const getFile = (blob, buffer) => {
		let file = null;
		try {
			file = new File([blob], "noname.mp3", { type: blob.type });
		} catch (err) {
			file = new File(buffer, "noname.mp3", {
				type: blob.type,
				lastModified: Date.now(),
			});
		}
		return file;
	};

	const getDuration = async (blob) => {
		let duration = 0;
		duration = await getBlobDuration(blob)
		.then((d) => {
			return d;
		})
		.catch(async (e) => {
			return await getBlobDuration(URL.createObjectURL(blob));
		});
		return duration;
	};

	useEffect(() => {
		if (listening === LISTENING) {
			startListening();
		} else if (listening === LISTENING_FINISH) {
			stopListening();
		}
	}, [listening]);

	useEffect(() => {
		if (blobFile !== null && fileBuffer !== null) {
			const submitPractice = async () => {
				const duration = await getDuration(blobFile);
				const file = getFile(blobFile, fileBuffer);
				const blobURL = getBlobURL(blobFile, file);
				setRecordedAudioURL(blobURL);

				const dataToSubmit = {
					data: file,
					audio_text: transcript,
					duration: duration,
					strategy: strategy,
				};
				sendToPracticeFile(dataToSubmit);
			}
			submitPractice();
		}
	}, [blobFile, fileBuffer]);

	return (
		<>
			<StayConnected />
			<SpeechToText
				updateTranscript={updateTranscript}
			/>
			<Col xs={12} className={strategy === NORMAL ? "" : "three-border"}>
				{strategy === ONELINE && <span className="border-label">BETA</span>}

				<div className="col-lg-12 text-center">
					{!resultMode &&
						<>
							<div className="scoring-mode">
								<button
									className={`btn ${strategy === NORMAL ? "btnActive" : ""}`}
									onClick={normalStrategy}
								>Normal</button>
								<button
									className={`btn ${strategy === ONELINE ? "btnActive" : ""}`}
									onClick={oneLineStrategy}
								>One Line Strategy</button>
							</div>

							<AudioBox
								questionData={questionData}
								updateListeningState={updateListeningState}
								listening={LISTENING}
								listening_finish={LISTENING_FINISH}
							/>
						</>
					}
					<Col xs={12}>
						{recordedAudioURL !== "" &&
							<audio src={recordedAudioURL} controls="controls" />
						}
					</Col>
				</div>
			</Col>
			<Col xs={12} className={strategy === NORMAL ? "" : "border-three"}>
				<h5>
					{filtered_question.split(" ").map(function (item, index) {
						return (
							<React.Fragment key={index}>
								<span
									className="speach-text"
									onClick={() => selectSpan(item)}
								>{item}</span>
								&nbsp;{" "}
							</React.Fragment>
						);
					}, this)}
				</h5>
			</Col>
		</>
	);
}